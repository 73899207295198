import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import {Carousel, Typography} from "antd";
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"

const {Text} = Typography;

export const query = graphql`
  query {
    file: file(relativePath: { eq: "slider_header.jpg" }) {
      publicURL
      }

      banner: file(relativePath: { eq: "banner.png" }) {
      publicURL
      }
  }
`

const IndexPage = ({data}) => (
    <Container>
        <SEO title="Home"/>
        <a href="https://teikojutsu.de/news/familien-raufen-sich-zusammen"><img src={data.banner.publicURL} style={{width:"70%", marginLeft:"15%", marginRight:"15%"}}/></a>
        <Carousel effect={"scrollx"}>
            <div>
            <div style={{width:"100%", height:800, backgroundImage:"url("+data.file.publicURL+")", backgroundSize:"cover"}} />
            </div>

        </Carousel>
        <div style={{padding: 50}}>
            <h1>Was ist Teikojutsu?</h1>
            <Text>
                Teikojutsu arbeitet aus der Situation heraus: "Was kann ich in dieser Situation machen?" Geschult
                werden u.a. harte, wie auch weichere Abwehr-Methoden, Körpersprache (verbal und nonverbal/Deeskalation,
                klare Stopp-Techniken und Befreiungsgriffe.
                Teikojutsu ist eine Form der Verteidigung, die für jeden, egal ob alt/jung und groß/klein, geschaffen
                ist. Sie setzt keine Vorkenntnisse voraus und ist auf jeden spezifisch anpassbar. Wir legen Wert darauf
                das jeder seinem Körper gerecht Techniken erlernt und diese auch beim Training aus probiert. Das
                Nahkampf-System Teikojutsu legt keinen Wert auf ästhetische Bewegungsabläufe. Lediglich die eigene
                Sicherheit ist entscheidend. Intuition, Reaktion und Kreativität ist gefragt. Auf der Straße sagt man
                dem Angreifer ja auch nicht." Bitte greif nochmal an, habe einen Fehler gemacht". Die Techniken in
                abgewandelter Form kommen auch im Aikido, Bukido, Kyusho-Jitsu, Jiu-Jitsu, Sambo und etc. vor. Jeder ist
                nach kürzerer Zeit in der Lage, sich zu wehren. Aber auch der Körper und Geist werden geschult: Stärkung
                des Muskelsystem, dehnen der Gliedmaßen, Gleichgewichts-, Koordination Training, gehören wie
                Ausdauertraining zu einem gesunden Aufwärmprogramm vor jedem Training.
                Gerade für Kinder ab 6 Jahren ist diese Trainingsform gut geeignet. Hier erlernen die Kinder ohne Gewalt
                (Treten und Stoßen) ganz klare Grenzen zu setzen. "Sich gerade machen" ist ein sehr wichtiges Element in
                der Persönlichkeitsentwicklung eines jeden Kindes! Das Gelernte wird durch Prüfungen abgefragt.
                Die Komplexität der Techniken steigt mit dem Grad der Graduierung.
            </Text>
        </div>
    </Container>
)

export default IndexPage
